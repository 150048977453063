import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { ListBulletIcon, NewspaperIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable'
import Spread from '../components/catalog-maker/Spread'
import ProductCard from '../components/catalog-maker/ProductCard'

import { algoliaSearch, extractFilter, deserializeFacets } from '../helpers/AlgoliaClient'
import TopQueryBar from '../components/catalog-maker/TopQueryBar'
import SortByBar from '../components/catalog-maker/SortByBar'

const downloadFile = (textContent, name = 'download.txt', type = 'text/plain') => {
  const blob = new Blob([textContent], { type })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}

const CatalogMaker = () => {
  const issues = [
    { value: 'January', name: 'January', number: 1 },
    { value: 'February', name: 'February', number: 2 },
    { value: 'Spring', name: 'Spring', number: 3 },
    { value: 'May', name: "May (Mother's Day)", number: 5 },
    { value: 'June', name: "June (Father's Day)", number: 6 },
    { value: 'July', name: 'July (Summer A)', number: 7 },
    { value: 'August', name: 'August (Wrap)', number: 8 },
    { value: 'September', name: 'September', number: 9 },
    { value: 'October', name: 'October', number: 10 },
    { value: 'November', name: 'November', number: 11 },
    { value: 'December', name: 'December (Wrap)', number: 12 },
  ]
  const [issue, setIssue] = useState('')
  const currentMonth = new Date().getMonth() + 1

  const issueNumber = issues.find(i => i.value === 'issue')?.number || new Date().getMonth() + 1
  const issueYear =
    currentMonth >= 7 && issueNumber < 7 ? new Date().getFullYear() + 1 : new Date().getFullYear()
  const [items, setItems] = useState([])
  const [holdItems, setHoldItems] = useState([])
  const pages = [
    { number: 1, rows: [4, 4, 4] }, // totalItems will be 11
    { number: 2, rows: [4, 4, 4] }, // totalItems will be 12
  ]

  const recalculateSpreads = spreads => {
    let accumulated = 0
    let newSpreads = spreads.map(spread =>
      spread.map(page => {
        const totalItems = page.rows.reduce((sum, n) => sum + n, 0)
        const skip = accumulated
        accumulated += totalItems
        return { ...page, totalItems, skip }
      })
    )
    return newSpreads
  }

  const [spreads, setSpreads] = useState(recalculateSpreads([pages]))
  const [currentSpread, setCurrentSpread] = useState(0)
  // const [items, setItems] = useState( products.nodes.map(item => ({ ...item, id: item.sku })))
  // const [holdItems, setHoldItems] = useState( hold.nodes.map(item => ({ ...item, id: item.sku })))
  //
  //
  const updateItem = (sku, { rating }) => {
    let item = items.find(item => item.sku === sku)
    let holdItem = holdItems.find(item => item.sku === sku)
    console.log('updateItem', sku, rating)
    if (item) {
      item.rating = rating
      setItems([...items])
    }
    if (holdItem) {
      holdItem.rating = rating
      setHoldItems([...holdItems])
    }
  }
  React.useEffect(() => {
    let itemsTotal = 0
    let itemsPerSpread = 0
    let currentSpread = 0
    // auto populate spreads
    for (let spreadConfig of spreads) {
      ++currentSpread
      const rowsP1 = spreadConfig[0].rows || [4, 4, 4]
      const rowsP2 = spreadConfig[1].rows || [4, 4, 4]
      itemsPerSpread =
        rowsP1.reduce((sum, cols) => sum + cols, 0) + rowsP2.reduce((sum, cols) => sum + cols, 0)
      itemsTotal = itemsTotal + itemsPerSpread
      if (items.length <= itemsTotal && spreads.length > currentSpread) {
        console.log('removing spread', currentSpread)
        setSpreads(spreads.slice(0, currentSpread))
        return
      }
    }
    if (items.length > itemsTotal) {
      let spreadConfig = spreads[spreads.length - 1]
      let newSpreads = []
      while (items.length > itemsTotal) {
        newSpreads.push(
          spreadConfig.map(page => ({
            ...page,
            skip: page.skip + (newSpreads.length + 1) * itemsPerSpread,
            number: page.number + 2 * (newSpreads.length + 1),
          }))
        )
        itemsTotal += itemsPerSpread
      }
      console.log('adding spreads', ...spreads, ...newSpreads)
      setSpreads([...spreads, ...newSpreads])
    }
  }, [spreads, items.length])

  const changeSpreadRow = (page, row, value) => {
    console.log('changeSpreadRow', page, row, value)
    let intVal = Math.min(Math.max(parseInt(value), 0), 12)
    if (row === 0) {
      spreads[currentSpread][page].rows = [intVal, ...spreads[currentSpread][page].rows]
    } else if (row > spreads[currentSpread][page].rows.length) {
      spreads[currentSpread][page].rows = [...spreads[currentSpread][page].rows, intVal]
    }
    spreads[currentSpread][page].rows[row - 1] = intVal
    spreads[currentSpread][page].rows = spreads[currentSpread][page].rows.filter(n => n > 0)
    if (spreads[currentSpread][page].rows.length === 0) {
      spreads[currentSpread][page].rows = [4]
    }
    setSpreads(recalculateSpreads(spreads))
  }
  const updatePageNumber = (page, value) => {
    let firstPage = Math.min(Math.max(value - page - 2 * currentSpread, 1), 1000)
    for (let i = 0; i < spreads.length; i++) {
      spreads[i].forEach((page, index) => {
        page.number = firstPage + index + 2 * i
      })
    }
    setSpreads(recalculateSpreads(spreads))
  }

  const spread = spreads[currentSpread].map(page => ({
    ...page,
    items: items.slice(page.skip, page.skip + page.totalItems),
  }))

  const generateHold = () => {
    downloadFile(
      JSON.stringify(
        holdItems.map(s => s.sku),
        null,
        2
      ),
      'hold.json',
      'application/json'
    )
  }
  const generateSpread = () => {
    if (!issue) {
      alert('Please select an issue and pages')
      return
    }
    let spreadsWithItems = spreads.map(spread => ({
      folder: issueYear + ' - ' + issue,
      name:
        issue +
        ' ' +
        spread[0].number.toString().padStart(2, '0') +
        '-' +
        spread[1].number.toString().padStart(2, '0'),
      pages: spread.map(page => ({
        ...page,
        items: items.slice(page.skip, page.skip + page.totalItems).map(i => i.sku),
      })),
    }))
    let name =
      issue +
      '-' +
      spreadsWithItems[0].pages[0].number +
      '-' +
      spreadsWithItems[spreadsWithItems.length - 1].pages[1].number
    const spreadInfo = {
      folder: issueYear + ' - ' + issue,
      name: issue,
      spreads: spreadsWithItems,
    }
    downloadFile(
      JSON.stringify(spreadInfo, null, 2),
      `spreads-${issueYear}-${name.toLowerCase().replace(/[^a-zA-Z0-9]+/, '-')}.json`,
      'application/json'
    )
  }
  // const [items, setItems] = useState([{ id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }, { id: '5' }].map(item => ({ id: item.id, sku: item.id })))
  // const [items, setItems] = useState([1,2,3,4,5])
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  function handleDragEndHold(event) {
    const { active, over } = event
    if (active.id !== over.id) {
      setHoldItems(holdItems => {
        const oldIndex = holdItems.map(item => item.id).indexOf(active.id)
        const newIndex = holdItems.map(item => item.id).indexOf(over.id)

        return arrayMove(holdItems, oldIndex, newIndex)
      })
    }
  }
  function handleDragEnd(event) {
    const { active, over } = event
    if (active.id !== over.id) {
      setItems(items => {
        const oldIndex = items.map(item => item.id).indexOf(active.id)
        const newIndex = items.map(item => item.id).indexOf(over.id)

        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }
  const findItems = async (url, rating) => {
    // split to lines
    const controller = new AbortController()
    const urls = url.split('\n')
    const skusToFind = []
    const itemsFound = []
    for (let url of urls) {
      const skuNumber = /[nswNSW]\d{3,7}/.exec(url)
      const urlTest = /https?:\/\/www.grayandsons.com\/(.*?)\//.exec(url)
      if (skuNumber) {
        skuNumber.forEach(sku => skusToFind.push(sku.toUpperCase()))
        continue
      }
      if (!urlTest) continue
      const fullUrl = new URL(url.trim())
      // fetch page data
      await fetch('/page-data' + fullUrl.pathname + 'page-data.json')
        .then(res => res.json())
        .then(async data => {
          const categoryId = data['result']['pageContext']['categoryId']

          const _facets = deserializeFacets(fullUrl.hash)
          const _keyword = (extractFilter(_facets, 'search', false) || '').toString().trim()
          const _sortBy = extractFilter(_facets, 'sort_by', false)
          const _minPrice = Number.parseFloat(extractFilter(_facets, 'min_price', false)) || -1
          const _maxPrice = Number.parseFloat(extractFilter(_facets, 'max_price', false)) || -1
          let _page = parseInt(extractFilter(_facets, 'page'))
          return algoliaSearch(
            _keyword,
            _page,
            _facets,
            _sortBy,
            controller,
            { strapi_id: categoryId },
            true,
            false,
            [_minPrice, _maxPrice],
            1000
          ).then(searchResponse => {
            itemsFound.push(...searchResponse.hits.map(item => ({ ...item, id: item.sku, rating })))
          })
        })
      console.log(fullUrl)
    }
    if (skusToFind && skusToFind.length > 0) {
      await algoliaSearch(
        '',
        0,
        [],
        '',
        controller,
        null,
        true,
        false,
        [-1, -1],
        1000,
        skusToFind
      ).then(searchResponse => {
        itemsFound.push(...searchResponse.hits.map(item => ({ ...item, id: item.sku, rating })))
      })
    }

    const skus = itemsFound.map(item => item.sku).join(',')
    await fetch('https://apps1.graynew.local:3443/Reports/GetProductStatus?skus= ' + skus)
      .then(res => res.json())
      .then(data => {
        data.data.forEach(item => {
          itemsFound.find(i => i.sku === item.sku).usages = item.usages
        })
        console.log(data)
      })
      .catch(err => console.error(err))

    return itemsFound
  }
  const addNewItems = async (url, rating, setUrl) => {
    const itemsFound = await findItems(url, rating)
    console.log(itemsFound)
    if (!itemsFound) return
    setUrl('')
    setItems(currentItems => {
      const mergedItems = [...currentItems, ...itemsFound]
      const uniqueItems = mergedItems.filter(
        (item, index, self) => index === self.findIndex(i => i.sku === item.sku)
      )
      return uniqueItems
    })
  }
  const removeItems = async (url, setUrl) => {
    // Get the items that should be removed.
    const itemsFound = await findItems(url, 0)
    console.log('Items to remove:', itemsFound)
    if (!itemsFound) return
    setUrl('')

    // Update the current items state by filtering out the items we want to remove.
    setItems(currentItems => {
      // If findItems returns nested arrays, flatten them.
      const itemsToRemove = itemsFound.flat()

      // Remove any current item that matches an item in itemsToRemove based on sku.
      return currentItems.filter(
        currentItem => !itemsToRemove.some(item => item.sku === currentItem.sku)
      )
    })
  }

  const removeDuplicates = () => {
    spread.forEach(page => {
      const itemsToRemove = page.items.filter(
        item => item.locked === undefined && isUsed(item, page.number)
      )
      setItems(currentItems => {
        return currentItems.filter(
          currentItem => !itemsToRemove.some(item => item.sku === currentItem.sku)
        )
      })
    })
  }

  /**
   * Remove an item (by sku) from both lists.
   * @param {string | number} sku - The unique sku identifier of the item.
   */
  const removeItem = sku => {
    setItems(prevItems => prevItems.filter(item => item.sku !== sku))
    setHoldItems(prevHoldItems => prevHoldItems.filter(item => item.sku !== sku))
  }

  /**
   * Move an item (by sku) from the items list to the holdItems list.
   * The item is appended as the last element in holdItems.
   * @param {string | number} sku - The unique sku identifier of the item.
   */
  const holdItem = sku => {
    setItems(prevItems => {
      // Find the item to hold
      const itemIndex = prevItems.findIndex(item => item.sku === sku)
      if (itemIndex === -1) {
        // Item not found in items list; do nothing.
        return prevItems
      }
      const itemToHold = prevItems[itemIndex]

      // Add the item to the holdItems list
      setHoldItems(prevHoldItems => [...prevHoldItems, itemToHold])

      // Remove the item from the items list
      return prevItems.filter(item => item.sku !== sku)
    })
  }
  const moveAllHoldItems = () => {
    setItems(prevItems => [...prevItems, ...holdItems])
    setHoldItems([])
  }
  const lockItem = sku => {
    console.log('lockItem', sku)
    setItems(prevItems => {
      // Find the item to hold
      const itemIndex = prevItems.findIndex(item => item.sku === sku)
      console.log('itemIndex', itemIndex)
      if (itemIndex === -1) {
        // Item not found in items list; do nothing.
        return prevItems
      }
      if (prevItems[itemIndex].locked === itemIndex) delete prevItems[itemIndex].locked
      else prevItems[itemIndex].locked = itemIndex
      // Remove the item from the items list
      return prevItems
    })
  }
  console.log('items', items)

  /**
   * Move an item (by sku) from the holdItems list back to the items list.
   * The item is appended as the last element in items.
   * @param {string | number} sku - The unique sku identifier of the item.
   */
  const returnItem = sku => {
    setHoldItems(prevHoldItems => {
      // Find the item to return
      const itemIndex = prevHoldItems.findIndex(item => item.sku === sku)
      if (itemIndex === -1) {
        // Item not found in holdItems list; do nothing.
        return prevHoldItems
      }
      const itemToReturn = prevHoldItems[itemIndex]

      // Add the item back to the items list
      setItems(prevItems => [...prevItems, itemToReturn])

      // Remove the item from the holdItems list
      return prevHoldItems.filter(item => item.sku !== sku)
    })
  }

  const [sortItems, setSortItems] = React.useState([
    { id: 'rating', name: 'Rating', checked: false, order: 'DESC' },
    { id: 'brand', name: 'Brand', checked: false, order: 'ASC' },
    { id: 'model', name: 'Model', checked: false, order: 'ASC' },
    { id: 'price', name: 'Price', checked: false, order: 'DESC' },
    { id: 'case_size_mm', name: 'Case Size', checked: false, order: 'DESC' },
  ])
  const applySorting = items => {
    // Separate locked and unlocked products.
    const lockedProducts = items
      .map((product, index) => ({ ...product, position: index }))
      .filter(product => product.locked !== undefined)
    const unlockedProducts = items.filter(product => product.locked === undefined)
    const activeSorts = sortItems.filter(sort => sort.checked)
    if (!activeSorts.length) {
      return items
    }
    const sorted = [...unlockedProducts].sort((a, b) => {
      // Loop through each active sort criteria in order
      for (const sort of activeSorts) {
        const key = sort.id
        const aValue = a[key]
        const bValue = b[key]
        let comparison = 0
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          comparison = aValue.localeCompare(bValue)
        } else {
          comparison = aValue > bValue ? 1 : aValue < bValue ? -1 : 0
        }
        if (comparison !== 0) {
          return sort.order === 'ASC' ? comparison : -comparison
        }
      }
      return 0
    })
    lockedProducts.forEach(item => {
      const pos = Math.max(0, Math.min(item.position, sorted.length))
      sorted.splice(pos, 0, item)
    })
    return sorted
  }
  const updateSpread = () => {
    setItems(applySorting(items))
  }

  const isUsed = (item, pageNumber) => {
    const currentMonthPages = (item.usages || []).filter(
      path =>
        path.startsWith(`/${issueYear} -`) &&
        path.toLowerCase().includes((issue || '').toLowerCase())
    )
    const spreads = currentMonthPages
      .map(path => {
        const regex = /(\d+)[\s-]+(\d+)/
        const match = path.match(regex)
        if (match) {
          // Convert the captured strings to numbers
          return [parseInt(match[1], 10), parseInt(match[2], 10)]
        }
        return [path, path]
      })
      .filter(spread => spread !== null)
    // 3. Filter out spreads that include the provided pageNumber
    const otherSpreads = spreads.filter(spread => !spread.includes(pageNumber))

    // 4. Return true if there are any spreads not containing the pageNumber
    return otherSpreads.length > 0
  }
  const addItemToPage = (sku, pageNumber) => {
    if (!pageNumber) return
    pageNumber = parseInt(pageNumber)
    if (Number.isNaN(pageNumber)) return
    if (pageNumber < 1 || pageNumber > 1000) return
    setHoldItems(prevHoldItems => {
      // Find the item to return
      const itemIndex = prevHoldItems.findIndex(item => item.sku === sku)
      if (itemIndex === -1) {
        // Item not found in holdItems list; do nothing.
        return prevHoldItems
      }
      const itemToReturn = prevHoldItems[itemIndex]

      const pageIndex = spread.findIndex(page => page.number === pageNumber)
      let pos = spread[pageIndex].skip + spread[pageIndex].totalItems
      let row = spread[pageIndex].rows.length
      if (spread[pageIndex].rows[row - 1] >= 8) {
        changeSpreadRow(pageIndex, row + 1, 1)
      } else {
        changeSpreadRow(pageIndex, row, spread[pageIndex].rows[row - 1] + 1)
      }
      // Add the item back to the items list
      setItems(prevItems => {
        prevItems.splice(pos, 0, itemToReturn)
        return prevItems
      })

      // Remove the item from the holdItems list
      return prevHoldItems.filter(item => item.sku !== sku)
    })
  }
  const deleteSpreads = () => {
    console.log('deleteSpreads', spread[1].skip + spread[1].totalItems)
    setItems(items => {
      return items.filter((i, indx) => indx < spread[1].skip + spread[1].totalItems)
    })
  }

  return (
    <Layout className={'max-w-[1800px] mx-auto'}>
      <Seo title="Current Dev" description="Gray and Sons Jewelry | Used rolex" noindex={true} />
      {/* <pre className="w-full whitespace-pre">{JSON.stringify(spreads, null, 2)}</pre> */}
      {/* <div className="grid grid-cols-[auto_auto_1fr_auto_auto] gap-4 h-28">
        <button onClick={updateSpread} className="size-24 bg-lime-500 text-white group">
          <ArrowPathIcon className="size-16 group-hover:animate-spin stroke-1 stroke-white m-auto" />
        </button>
        <RatingComponent />
        <textarea
          type="text"
          placeholder="URL or SKU number(s) here"
          className="border p-3 w-full h-24"
          onChange={updateUrl}
          value={url}
        />
        <button
          onClick={addNewItems}
          className="size-24 bg-emerald-600 text-white px-4 group flex justify-center items-center"
        >
          <PlusCircle className="size-14 group-hover:size-16 duration-200 ease-[cubic-bezier(.59,-0.27,.76,1.99)] group-hover:drop-shadow-lg" />
        </button>
        <button
          onClick={removeItems}
          className="size-24 bg-red-600 text-white px-4 group flex justify-center items-center"
        >
          <MinusCircle className="size-14 group-hover:size-16 duration-200 ease-[cubic-bezier(.59,-0.27,.76,1.99)] group-hover:drop-shadow-lg" />
        </button>
      </div> */}
      <TopQueryBar
        updateSpread={updateSpread}
        addNewItems={addNewItems}
        removeItems={removeItems}
      />
      <SortByBar
        totalItems={items.length}
        sortItems={sortItems}
        setSortItems={setSortItems}
        issue={issue}
        issues={issues}
        issueYear={issueYear}
        setIssue={setIssue}
        removeDuplicates={removeDuplicates}
        isUsed={isUsed}
      />

      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={items} strategy={rectSortingStrategy} id="sku">
          <Spread
            spread={spread}
            ProductCard={ProductCard}
            generateSpread={generateSpread}
            holdItem={holdItem}
            removeItem={removeItem}
            lockItem={lockItem}
            currentSpread={currentSpread}
            setCurrentSpread={setCurrentSpread}
            spreads={spreads}
            changeSpreadRow={changeSpreadRow}
            updatePageNumber={updatePageNumber}
            updateItem={updateItem}
            isUsed={isUsed}
            deleteSpreads={deleteSpreads}
          />
        </SortableContext>
      </DndContext>

      {holdItems.length > 0 && (
        <>
          <div className=" flex gap-4 flex-row bg-gray-100 w-full overflow-x-auto *:w-[15rem] *:min-w-[15rem] *:bg-white *:p-3 *:border-b *:border-gray-300 *:rounded-lg p-5 *:mix-blend-normal">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEndHold}
            >
              <SortableContext items={holdItems} strategy={rectSortingStrategy} id="sku">
                {holdItems.map(item => (
                  <ProductCard
                    product={item}
                    key={item.sku}
                    isOnHold={true}
                    id={item.sku}
                    holdItem={returnItem}
                    removeItem={removeItem}
                    updateItem={updateItem}
                    spread={spread}
                    addItemToPage={addItemToPage}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </div>
          <div className="w-full h-24 flex flex-row justify-center items-center gap-4 mb-10">
            <button
              onClick={moveAllHoldItems}
              className="w-max bg-green-600 text-white px-4 py-2 text-lg font-bold flex flex-row items-center justify-center gap-2"
            >
              <NewspaperIcon className="size-8" /> RETURN ALL TO SPREAD
            </button>
            <button
              onClick={generateHold}
              className="w-max bg-cyan-500 text-white px-8 py-2 text-lg font-bold flex flex-row items-center justify-center gap-2"
            >
              <div className="size-7 flex bg-white text-cyan-500 justify-center items-center stroke-1 stroke-cyan-500">
                <ListBulletIcon className="size-6" />
              </div>
              GENERATE LIST
            </button>
          </div>
        </>
      )}
    </Layout>
  )
}

export default CatalogMaker
