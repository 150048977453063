import * as React from 'react'
import RatingComponent from './RatingComponent'
import { ArrowPathIcon, MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid'

const TopQueryBar = ({ updateSpread, addNewItems, removeItems }) => {
  const [rating, setRating] = React.useState(0)

  const [url, setUrl] = React.useState('')
  const updateUrl = React.useCallback(e => {
    setUrl(e.target.value)
  })
  return (
    <>
      <div className="grid grid-cols-[auto_auto_1fr_auto_auto] gap-4 h-28">
        <button onClick={updateSpread} className="size-24 bg-lime-500 text-white group">
          <ArrowPathIcon className="size-16 group-hover:animate-spin stroke-1 stroke-white m-auto" />
        </button>
        <RatingComponent rating={rating} setRating={setRating} />
        <textarea
          type="text"
          placeholder="URL or SKU number(s) here"
          className="border p-3 w-full h-24"
          onChange={updateUrl}
          value={url}
        />
        <button
          onClick={() => addNewItems(url, rating, setUrl)}
          className="size-24 bg-emerald-600 text-white px-4 group flex justify-center items-center"
        >
          <PlusCircleIcon className="size-14 group-hover:size-16 duration-200 ease-[cubic-bezier(.59,-0.27,.76,1.99)] group-hover:drop-shadow-lg" />
        </button>
        <button
          onClick={() => removeItems(url, setUrl)}
          className="size-24 bg-red-600 text-white px-4 group flex justify-center items-center"
        >
          <MinusCircleIcon className="size-14 group-hover:size-16 duration-200 ease-[cubic-bezier(.59,-0.27,.76,1.99)] group-hover:drop-shadow-lg" />
        </button>
      </div>
    </>
  )
}

export default TopQueryBar
